@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"










































































.footer-segment
  z-index: $z-index-base
  background-color: $white-darker
  margin-top: m(3)
  .content
    background-color: $white-darker
    .main
      .contact
        .row
          display: block
          .col
            white-space: nowrap
            .address
              p:first-child
                +font('bold')
      .map
        #map
          width: 100%
          height: 100%

+sm
  .footer-segment
    .main
      display: block
      .contact
        .row
          display: flex
          flex-wrap: wrap
      .map
        #map
          height: 240px

+xs
  .footer-segment
    .main
      .map
        margin-left: - content-padding('xs')
        margin-right: - content-padding('xs')
        padding-left: 0
        padding-right: 0
