@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"




























































.image-elem
  font-size: 0
  line-height: 0
// no display: flex !!
