@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"


















































































.default-template
  .content-segment
    .content
      padding-top: m(4)
      padding-bottom: m(4)
      transition: all .4s cubic-bezier(.55,0,.1,1)
      &.fade-enter-active,
      &.fade-leave-active
        transition: opacity .5s ease
      &.fade-enter,
      &.fade-leave-active
        opacity: 0
