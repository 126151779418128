@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"

























































































































































































































.sections-elem
  .section
    margin-top: m(6)
    &:first-child
      margin-top: 0
    &.images
      .col
        padding: col-gap()
        background-color: $white-darker
      &.is-slider
        .col
          padding-left: 0
          padding-right: 0
          background-color: transparent
    &.boxes
      >.col
        padding: (col-gap() / 2) col-gap()
        background-color: $white-darker
    &.links
      .col
        ul
          display: flex
          justify-content: center
          flex-wrap: wrap
          li
            margin: m(1)
    &.video
      .col
        h2
          margin-bottom: m(2)

+md
  .sections-elem
    .section
      &.images
        .col
          padding: col-gap('md')
      &.boxes
        >.col
          padding: (col-gap('md') / 2) col-gap('md')
          background-color: $white-darker

+sm
  .sections-elem
    .section
      margin-top: m(3)
      &:first-child
        margin-top: 0
      &.images
        .col
          padding: col-gap('sm')
      &.boxes
        >.col
          padding: (col-gap('sm') / 2) col-gap('sm')
          background-color: $white-darker

+xs
  .sections-elem
    .section
      margin-top: m(2)
      &.images
        .col
          padding: col-gap('xs')
      &.boxes
        >.col
          padding: (col-gap('xs') / 2) col-gap('xs')
          background-color: $white-darker
