@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"

























.header-segment
  margin-bottom: m(3)
  border-bottom: 1px solid $border-color
  .content
    padding-top: $nav-toolbar-height + m(3)
    padding-bottom: m(3)
    .logo
      line-height: 0
      .sprite-logo
        height: m(8)
        transition: all .5s
        fill: $yellow
    .navigation-elem
      margin-top: m(4)

+sm
  .header-segment
    margin-bottom: 0
    .content
      .navigation-elem
        margin-top: 0

+xs
  .header-segment
    margin-bottom: 0
    .content
      display: flex
      justify-content: center
      .sprite-logo
        height: m(6)
      .navigation-elem
        margin-top: 0

