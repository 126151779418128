@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"























.footer-nav-segment
  .content
    padding-bottom: m(3)
    border-top: 1px solid $border-color
    .nav-footer
      display: flex
      +font('small')
      li
        margin-right: m(2)
        &:last-child
          margin-right: 0

+xs
  .footer-nav-segment
    .nav-footer
      display: block
