@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"







































































.cookie-dialog-elem
  position: fixed
  display: flex
  flex-direction: column
  align-items: center
  left: content-padding()
  right: content-padding()
  bottom: content-padding()
  padding: m(2)
  background-color: $grey-light
  transition: all .8s ease
  border-radius: $radius
  transform: translateY(120%)
  transition: all .8s ease
  z-index: $z-index-dialog
  p
    text-align: center
  .button
    margin: m(2) 3px 0 3px
  &.animate
    transform: none

+sm
  .cookie-dialog-elem
    left: 0
    right: 0
    bottom: 0
    border-radius: none
    width: 100%
