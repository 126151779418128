@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"














































.button-elem
  position: relative
  &.move-down
    &:before
      padding-top: .2em
  &.move-up
    &:before
      padding-bottom: .2em
  &.move-left
    &:before
      padding-right: .3em
  &.move-right
    &:before
      padding-left: .3em
  &.has-spinner
    color: transparent !important
    &:before
      color: transparent !important
    .spinner-elem
      display: inline-block
      position: absolute
      left: 50%
      top: 50%
      width: 60px
      margin-left: -30px
      margin-top: -10px
      animation-delay: .2s
      div
        background-color: $grey
